<template>
    <div>
        <LoginComponent v-if="userinfo == null"></LoginComponent>
        <MainComponent v-else></MainComponent>
        <div id="mian-bg"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import MainComponent from "@/components/MainComponent";
import LoginComponent from "@/components/LoginComponent";

export default {
    data() {
        return {
            currentNavbar: "message",
        };
    },
    computed: {
        ...mapState(["userinfo"]),
    },
    components: {
        MainComponent,
        LoginComponent,
    },
    created() {

        

    },
    methods: {},
};
</script>

<style lang="scss" scoped>
#mian-bg {
    background-image: url("@/assets/images/bg.png");
    background-size: cover;
    background-position: center;
    filter: blur(5px);
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

}

.system-navbar {
    height: 100%;
    width: 60px;
    background-color: rgba(46, 46, 46);
}
.system-body {
    width: calc(100% - 60px);
}

.system-navbar-group {
    margin-bottom: 20px;
}
.system-navbar-item {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}
.system-navbar-item.current {
    color: rgb(7, 193, 96);
}
.system-navbar-main {
    padding: 10px;
}
</style>